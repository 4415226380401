<template>
  <div>
    <h4><strong>Filter</strong></h4>
    <hr />
    <b-row>
      <b-col sm="3">
        <b-form-group>
          <label for="Title">From Date</label>
          <b-form-input
            type="date"
            required
            v-model="event_start_date"
            id="Title"
            placeholder="Name"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <label for="Title">To Date</label>
          <b-form-input
            type="date"
            required
            v-model="event_end_date"
            id="Title"
            placeholder="Name"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <label for="Title">Event Name</label>
          <b-form-select
            class="form-control"
            label="booking_type"
            text-field="e_name"
            value-field="e_name"
            v-model="event_name"
            :options="event_name_options"
            placeholder=""
          >
          <template slot="first">
            <option :value="''" disabled>Select Event</option>
          </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group>
          <label for="Title">Status</label>
          <b-form-select
            class="form-control"
            label="booking_type"
            v-model="event_status"
            :options="event_status_options"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center">
      <b-button @click="filter()">Search</b-button>
      <b-button class="ml-5" @click="resetData()">Reset</b-button>
    </b-row>
    <hr />
    <h4><strong>Events Details</strong></h4>
    <hr />

    <div class="animated">
    <div id="snackbar"></div>
    <b-card>
      <!-- <b-card-header>
        <i class="icon-menu mr-1"></i>List of all Events
        <div class="card-header-actions"></div>
      </b-card-header> -->
      <b-card-body>
        <v-client-table
          :columns="columns"
          :data="data"
          :options="options"
          :theme="theme"
          id="dataTable"
        >
          <div slot="ID" slot-scope="props">
            <i class="fa fa-edit" @click="editEvent(props.row.event_id)"></i>
          </div>

          <div slot="booking_open" slot-scope="props">
            <span v-if="props.row.booking_open == 1">Active</span>
            <span v-if="props.row.booking_open == 2">Inactive</span>
          </div>
        </v-client-table>

         <b-pagination
          limit="10"
          v-model="currentPage"
          :per-page="pagination.pageSize"
          :total-rows="pagination.total"
      ></b-pagination>
      </b-card-body>
    </b-card>
  </div>

  </div>
</template>

<script>
import MasterService from "@/services/MasterService";

import { Switch as cSwitch } from "@coreui/vue";
import _ from "lodash";

export default {
  name: "AllMovies",
  components: {
    cSwitch
  },
  data: function() {
    return {
      currentPage: 1,
       pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      columns: [
        "event_id",
        "e_name",
        "es_date",
        "ee_date",
        "booking_open",
        "ID"
      ],
      data: [],
      options: {
        headings: {
          event_id: "Event Id",
          e_name: "Event Name",
          es_date: "Start Date",
          ee_date: "End Date",
          booking_open: "Status",
          ID: "Edit"             
        },
        sortable: ["e_name"],
        filterable: ["e_name"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      params: "",
      event_start_date: "",
      event_end_date: "",
      event_name: "",
      event_name_options: [],
      event_status: 1,
      event_status_options: [
        { value: 1, text: "Active" },
        { value: 2, text: "InActive" },
      ],
      allData: [],
      eventList: [],
    };
  },
  mounted() {
    this.filter();
    this.getAllEventsDropdown();
  },

    watch: {
    currentPage: {
      handler: function (value) {
        this.params = `/page=${value}&size=${this.pagination.pageSize}`;
        this.filter();
      },
    },
  },

  methods: {
    // getEvents() {

    //   this.data = [];
    //   MasterService.getEvent()
    //   .then((response) => {
    //     this.eventList = response.data.data;
    //     this.allData = response.data.data;
    //     this.event_name_options = response.data.data.map(el=> el.e_name);
    //     this.data = response.data.data;

    //   })
    //   .catch((error) => {
    //     console.log("Catch on Getting Event Error: ", error);
    //   });
    // },

    getAllEventsDropdown() {
       MasterService.getAllPageEvent().then((response) => {
        console.log("responseevnts", response.data);
        if (response.error) {
          this.showMessageSnackbar(response.error);
        } else {
          // this.event_name_options = response.data.data;
          this.event_name_options = this.uniqueArrayOfObject(response.data.data, 'e_name');
          console.log("eventList", this.event_name_options);
        }
      });
    },

      uniqueArrayOfObject: function (array, keyToBeUnique) {
        return Object.values(array.reduce((tmp, x) => {
          // You already get a value
          if (tmp[x[keyToBeUnique]]) return tmp;

          // You never envcountered this key
          tmp[x[keyToBeUnique]] = x;

          return tmp;
        }, {}));
      },

    filter() {

      this.params = `?page=${this.currentPage}`;

      let payload = {
        event_name: this.event_name,
        booking_open: this.event_status,
        event_start_date: this.event_start_date,
        event_end_date: this.event_end_date,
      }

      MasterService.filterEvents(this.params, payload)
        .then((response) => {
          this.data = response.data.data     
          this.pagination.total = response.data.pagination.total
          this.currentPage = response.data.pagination.current
          this.pagination.pageSize = response.data.pagination.pageSize      
        })
        .catch((error) => {
          console.log("Catch on Getting Reports Filter ", error);
        });
    },

    resetData() {
      this.filteredData = this.allData;
      this.event_start_date = "";
      this.event_end_date = "";
      this.event_name = "";
      this.event_status = 1;
      this.filter();
    },

    editEvent: function(event_id) {
      this.$router.push({
        path: "/eventinfo/addeventinfo",
        query: { event_id }
      });
    },
    
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
.table-bordered tbody td {
  text-align: center;
}
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
.banner-list li {
  padding: 20px;
  list-style-type: none;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #eee;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.banner-list li .row .col-2,
.col-3 {
  text-align: center;
}
</style>
